import React, { useEffect, Fragment, useRef, useCallback, useReducer } from "react";
import mitt from 'mitt'
import { ID_AMAP_MAP } from "../../config";
import { getMapMarkerByFilter } from "../../api/map";
import './index.css';
import { InfoWindow } from "./components/InfoWindow";
import { Search } from "./components/Search";
import { calcDayOrNight, MAP_THEME_DAY, MAP_THEME_NIGHT, JS_CENTER_LAT, JS_CENTER_LNG, renderCustomMarkers } from "../../utils/utils";

const { AMap } = window
const emitter = mitt()

/** 请求定位 */
const EVENT_MAP_LOCATION = '__event_map_location__'

/** 全局函数：用于接收RN-APP发送的参数，并分发给指定的事件监听器 */
window.emitReactNativeEvent = (name, message) => {
    switch (name) {
        case EVENT_MAP_LOCATION:
            emitter.emit(EVENT_MAP_LOCATION, JSON.parse(message));
            break;
        default:
            break;
    }
}

/** 获取标记点 */
const getMarker = path => name => `/static/crop/marker/${path}/${name}.png`

/** 获取默认标记点图片 */
const getDefaultMarker = getMarker('default')

/** 获取高亮标记点 */
const getHighlightMarker = getMarker('highlight')

/** 获取选中标记点 */
const getSelectedMarker = getMarker('select')

const initialState = {
    /** 选中点的下标 */
    selectedMarkerId: 0,
    /** 地图信息窗口数据 */
    infoWindow: undefined,
    /** 选中的8类可交互坐标组名称 */
    highlightType: undefined,
    /** 可搜索标记点的元数据 */
    searchableMarkers: [],
}

const reducer = (prevState, msg) => {
    switch (msg.type) {
        case 'changeHighlight': // 修改高亮的8类坐标组
            return {
                ...prevState,
                highlightType: prevState.highlightType === msg.payload ? undefined : msg.payload,
            }
        case 'changeSelected':    // 修改选中的marker
            return {
                ...prevState,
                selectedMarkerId: msg.payload,
            }
        case 'setMarkers':  // 设置标记点
            return {
                ...prevState,
                searchableMarkers: msg.payload,
            }
        case 'setInfoWindow': // 消息窗口
            return {
                ...prevState,
                infoWindow: msg.payload,
            }
        case 'update':  // 更新操作
            return {
                ...prevState,
                ...(msg.payload || {}),
            }
        default:
            return prevState;
    }
}

/**
 * App首页用地图
 * @description 高德地图实现；显示瓦片图；定位功能；大量marker标记
 */
export const MapWithTile = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        infoWindow,
        highlightType,
        searchableMarkers,
        selectedMarkerId,
    } = state;

    /** 气泡标记点 */
    const _bubbleMarkers = useRef([]);
    /** 特殊标记点（例燕子洞） */
    const _specialMarkers = useRef([]);
    /** 手绘标记点 */
    const _customMarkers = useRef([]);
    /** 地图控件示例 */
    const _map = useRef(null);
    /** 视图是否首次状态完毕 */
    const _didMount = useRef(false);
    /** 我的定位标记点 */
    const _mineMarker = useRef(undefined);

    /**
     * 事件处理
     * @description 点击自定义marker点；设为中心；
     */
    const _handleMarkerClick = useCallback(
        item => {
            dispatch({
                type: 'changeSelected',
                payload: item.id,
            });
            // 设为中心点
            _map.current.panTo(new AMap.LngLat(item.lng, item.lat));
            // 弹出信息窗口
            dispatch({
                type: 'setInfoWindow',
                payload: item,
            });
        },
        [],
    );

    /**
     * 事件处理
     * @description 监听地图的缩放；纯函数
     */
    const _handleMapZoom = useCallback(
        mapEl => {
            const _zoom = mapEl.getZoom();
            // 手绘标记点
            _customMarkers.current.forEach(marker => {
                const { zoom2Show } = marker.getExtData();
                if (zoom2Show < _zoom) {
                    marker.hide();
                } else {
                    marker.show();
                }
            });
            // 气泡标记点
            _bubbleMarkers.current.forEach(marker => {
                const { zoom } = marker.getExtData();
                if (zoom) {
                    if (zoom <= _zoom) {
                        marker.show();
                    } else {
                        marker.hide();
                    }
                }
            });
            // 特殊标记点
            _specialMarkers.current.forEach(marker => {
                const { zoom } = marker.getExtData();
                if (zoom) {
                    if (zoom <= _zoom) {
                        marker.show();
                    } else {
                        marker.hide();
                    }
                }
            });
        },
        [],
    );

    /**
     * 事件处理
     * @description 用户手动定位
     */
    const _handleLocation = useCallback(
        () => {
            if (window.ReactNativeWebView) {
                const event = {
                    name: EVENT_MAP_LOCATION,
                };
                window.ReactNativeWebView.postMessage(JSON.stringify(event));
            }
        },
        [],
    );

    /**
     * 事件处理
     * @description 点击八大类高亮
     */
    const _handleHighlightChange = useCallback(
        payload => {
            dispatch({
                payload,
                type: 'changeHighlight',
            });
        },
        [],
    );

    /**
     * 事件处理
     * @description 点击地图非Marker点位置
     */
    const _handleMapClick = useCallback(
        () => {
            dispatch({
                type: 'update',
                payload: {
                    selectedMarkerId: 0,
                    infoWindow: undefined,
                },
            });
        },
        [],
    );

    /**
     * 事件处理
     * @description 点击搜索结果跳转
     */
    const _handleResultPress = useCallback(
        item => _handleMarkerClick(item),
        [_handleMarkerClick],
    );

    /**
     * 监听事件
     * @description 展示用户定位
     */
    const _onLocation = useCallback(
        e => {
            const { lng, lat } = e;
            const $map = _map.current;
            AMap.convertFrom([lng, lat], 'gps', (status, result) => {
                if (result.info === 'ok') {
                    const {
                        lng: $lng,
                        lat: $lat,
                    } = result.locations[0];

                    // 置于屏幕中间
                    $map.panTo(new AMap.LngLat($lng, $lat));
                    // 显示用户定位
                    let $marker = _mineMarker.current;
                    if ($marker) {
                        $marker.setPosition(new AMap.LngLat($lng, $lat));
                    } else {
                        $marker = new AMap.Marker({
                            position: new AMap.LngLat($lng, $lat),
                            icon: new AMap.Icon({
                                size: new AMap.Size(27, 41),
                                imageSize: new AMap.Size(27, 41),
                                image: '/static/crop/default-marker.png',
                            }),
                            // anchor: 'bottom-center',    // 锚点底部中心
                        });;
                        $map.add($marker);
                    }
                }
            });
        },
        [],
    );

    /**
     * 构建地图
     * @description 手绘瓦片图；根据白天和黑夜显示
     */
    const _initTileMap = useCallback(
        (mapEl, dayOrNight) => {
            const tileLayer = new AMap.TileLayer.Flexible({
                zIndex: 2,
                cacheSize: 256,
                zooms: [15, 20],
                createTile: (x, y, z, success, fail) => {
                    const img = document.createElement('img');
                    img.onload = () => success(img);
                    img.onerror = () => fail();
                    img.src = `/static/tiles/${dayOrNight}/${z}/${x}_${y}.png`;
                },
            });
            mapEl.add(tileLayer);
        },
        [],
    );

    /**
     * 构建地图
     * @description 异步的；获取后台配置的marker点
     */
    const _initOnlineMarkers = useCallback(
        async mapEl => {
            const result = await getMapMarkerByFilter();
            const $variableMarkers = result.variableMarkers.map((marker, index) => {    // 可交互标记点
                const $marker = new AMap.Marker({
                    position: new AMap.LngLat(
                        marker.lng,
                        marker.lat,
                    ),
                    icon: new AMap.Icon({
                        size: new AMap.Size(31, 41),
                        imageSize: new AMap.Size(31, 41),
                        image: getDefaultMarker(marker.cateName),
                    }),
                    extData: marker,
                    // anchor: 'bottom-center',    // 锚点底部中心
                });
                $marker.on('click', () => _handleMarkerClick(marker));
                return $marker;
            });

            const $staticMarkers = result.staticMarkers.map(marker => { // 静态点
                const $marker = new AMap.Marker({
                    position: new AMap.LngLat(
                        marker.lng,
                        marker.lat,
                    ),
                    icon: new AMap.Icon({
                        size: new AMap.Size(54, 43.5),
                        imageSize: new AMap.Size(54, 43.5),
                        image: marker.symbol,
                    }),
                    extData: marker,
                    // anchor: 'middle-center',
                });
                return $marker;
            });

            dispatch({
                type: 'setMarkers',
                payload: result.variableMarkers,
            });

            // 记录引用，添加至地图中
            _bubbleMarkers.current = $variableMarkers;
            _specialMarkers.current = $staticMarkers;

            mapEl.add(_specialMarkers.current);
            mapEl.add(_bubbleMarkers.current);
        },
        [_handleMarkerClick],
    );

    /**
     * 构建地图
     * @description 绘制本地配置的手绘标
     */
    const _initCustomMarkers = useCallback(
        mapEl => {
            const $markers = _customMarkers.current = renderCustomMarkers();
            $markers.forEach(it => it.hide());
            mapEl.add($markers);
        },
        [],
    );

    /**
     * 初始化
     * @description 构建地图
     */
    useEffect(
        () => {
            const dayOrNight = calcDayOrNight();
            const $map = _map.current = new AMap.Map(
                ID_AMAP_MAP,
                {
                    zoom: 15,
                    center: [JS_CENTER_LNG, JS_CENTER_LAT],
                    features: ['bg', 'road', 'point'],
                    mapStyle: dayOrNight === 'day' ? MAP_THEME_DAY : MAP_THEME_NIGHT,
                },
            );
            $map.on('zoomend', () => _handleMapZoom($map));
            $map.on('click', _handleMapClick);

            // 绘制其它地图元素
            _initTileMap($map, dayOrNight);
            _initCustomMarkers($map);
            _initOnlineMarkers($map);
        },
        [
            _initTileMap,
            _initOnlineMarkers,
            _initCustomMarkers,
            _handleMapZoom,
            _handleMapClick,
        ],
    );

    /**
     * 监听器
     * @description 监听定位
     */
    useEffect(
        () => {
            emitter.on(EVENT_MAP_LOCATION, _onLocation);
            return () => {
                emitter.off(EVENT_MAP_LOCATION, _onLocation);
            }
        },
        [_onLocation],
    );

    /**
     * 监听器
     * @description 监听选中、高亮状态的变化
     */
    useEffect(
        () => {
            if (!_didMount.current) {
                _didMount.current = true;
                return;
            }

            const $variableMarkers = _bubbleMarkers.current || [];
            $variableMarkers.forEach(marker => {
                const {
                    id,
                    cateName,
                } = marker.getExtData();

                if (cateName === highlightType) {
                    if (id === selectedMarkerId) {
                        // 选中效果
                        marker.setIcon(new AMap.Icon({
                            size: new AMap.Size(39, 50),
                            imageSize: new AMap.Size(39, 50),
                            image: getSelectedMarker(cateName),
                        }));
                    } else {
                        // 高亮效果
                        marker.setIcon(new AMap.Icon({
                            size: new AMap.Size(39, 50),
                            imageSize: new AMap.Size(39, 50),
                            image: getHighlightMarker(cateName),
                        }));
                    }
                } else {
                    if (id === selectedMarkerId) {
                        // 选中效果
                        marker.setIcon(new AMap.Icon({
                            size: new AMap.Size(39, 50),
                            imageSize: new AMap.Size(39, 50),
                            image: getSelectedMarker(cateName),
                        }));
                    } else {
                        // 默认状态效果
                        marker.setIcon(new AMap.Icon({
                            size: new AMap.Size(31, 41),
                            imageSize: new AMap.Size(31, 41),
                            image: getDefaultMarker(cateName),
                        }));
                    }
                }
            });
        },
        [
            highlightType,
            selectedMarkerId,
        ],
    );

    /**
     * 渲染
     * @description 侧边栏按钮
     */
    const _renderSide = useCallback(
        arr => {
            return arr.map(side => {
                const src = side.name === highlightType ? side.active : side.src;
                return (
                    <img
                        alt=''
                        src={src}
                        key={side.name}
                        className='tile_side-icon'
                        onClick={() => _handleHighlightChange(side.name)}
                    />
                )
            })
        },
        [
            highlightType,
            _handleHighlightChange,
        ],
    );

    return (
        <Fragment>
            {/* 地图 */}
            <div id={ID_AMAP_MAP} />
            {/* 左侧标记点 */}
            <div className='tile_side tile_side-left'>
                {
                    _renderSide(SIDE_LEFT)
                }
            </div>
            {/* 右侧标记点 */}
            <div className='tile_side tile_side-right'>
                {
                    _renderSide(SIDE_RIGHT)
                }
                <img
                    alt=''
                    src={img_location}
                    onClick={_handleLocation}
                    className='tile_side-icon tile_side-location'
                />
            </div>
            {/* 信息窗口 */}
            {
                infoWindow ?
                    <InfoWindow item={infoWindow} /> : null
            }
            {/* 搜索框 */}
            <Search data={searchableMarkers} onResultPress={_handleResultPress} />
        </Fragment>
    )
}

const img_location = '/static/crop/side/location.png';

const getDefaultSideImg = name => `/static/crop/side/default/${name}.png`

const getActiveSideImg = name => `/static/crop/side/active/${name}.png`

const SIDE_LEFT = [
    {
        name: 'traffic',
        src: getDefaultSideImg('traffic'),
        active: getActiveSideImg('traffic'),
    },
    {
        name: 'toilet',
        src: getDefaultSideImg('toilet'),
        active: getActiveSideImg('toilet'),
    },
    {
        name: 'park',
        src: getDefaultSideImg('park'),
        active: getActiveSideImg('park'),
    },
];

const SIDE_RIGHT = [
    {
        name: 'entertainment',
        src: getDefaultSideImg('entertainment'),
        active: getActiveSideImg('entertainment'),
    },
    {
        name: 'shop',
        src: getDefaultSideImg('shop'),
        active: getActiveSideImg('shop'),
    },
    {
        name: 'scene',
        src: getDefaultSideImg('scene'),
        active: getActiveSideImg('scene'),
    },
    {
        name: 'accommodation',
        src: getDefaultSideImg('accommodation'),
        active: getActiveSideImg('accommodation'),
    },
    {
        name: 'food',
        src: getDefaultSideImg('food'),
        active: getActiveSideImg('food'),
    },
];