import React, { useEffect, useRef, useCallback } from "react";
import { ID_AMAP_MAP } from "../../config";
import { calcDayOrNight, JS_CENTER_LNG, JS_CENTER_LAT, MAP_THEME_DAY, MAP_THEME_NIGHT } from "../../utils/utils";
import { getRouteMarkers } from "../../api/map";

const { AMap, AMapUI } = window

/**
 * 显示线路规划用地图
 * @description 基于百度地图实现
 */
export function MapWithRoute({ location }) {
    const params = new URLSearchParams(location.search);
    const id = params.get('id') || 0;
    const _map = useRef(null);

    /**
     * 渲染
     * @description 渲染线路
     */
    const _renderRoute = useCallback(
        markers => {
            const $map = _map.current;
            AMapUI.load(['ui/misc/PathSimplifier', 'lib/$'], PathSimplifier => {
                const pathSimplifierIns = new PathSimplifier({
                    zIndex: 100,
                    map: $map,
                    renderOptions: {
                        renderAllPointsIfNumberBelow: 100 //绘制路线节点，如不需要可设置为-1
                    },
                    getPath: function (pathData, pathIndex) {
                        return pathData.path;
                    },
                });

                // 连线
                const path = markers.map(({ lng, lat }) => [lng, lat]);
                pathSimplifierIns.setData([
                    {
                        name: 'route',
                        path,
                    },
                ]);
                // 绘制导航器
                const navi = pathSimplifierIns.createPathNavigator(0, {
                    loop: true,
                    speed: 10000,   // TODO: 速度需要计算
                });
                navi.start();
            });
        },
        [],
    );

    /**
     * 联机处理
     * @description 获取线路数据
     */
    const _fetchRouteData = useCallback(
        async () => {
            const result = await getRouteMarkers(id);
            if (Array.isArray(result) && result.length > 0) {
                _renderRoute(result);
            }
        },
        [
            id,
            _renderRoute,
        ],
    );

    /**
     * 初始化
     * @description 构建地图
     */
    useEffect(
        () => {
            const dayOrNight = calcDayOrNight();
            _map.current = new AMap.Map(
                ID_AMAP_MAP,
                {
                    zoom: 15,
                    center: [JS_CENTER_LNG, JS_CENTER_LAT],
                    features: ['bg', 'road', 'point'],
                    mapStyle: dayOrNight === 'day' ? MAP_THEME_DAY : MAP_THEME_NIGHT,
                },
            );
            // 获取线路数据
            _fetchRouteData();
        },
        [_fetchRouteData],
    );

    return <div id={ID_AMAP_MAP}></div>
}