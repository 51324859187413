import React, { useEffect, useRef, useCallback } from "react";
import { ID_AMAP_MAP } from "../../config";
import { calcDayOrNight, MAP_THEME_DAY, MAP_THEME_NIGHT } from "../../utils/utils";

const { AMap } = window;

/**
 * 显示某地标用地图
 * @description 基于高德地图实现
 */
export function MapWithMarker({ location }) {
    const params = new URLSearchParams(location.search);
    const lng = params.get('lng') || 0;
    const lat = params.get('lat') || 0;
    const _map = useRef(null);

    /**
     * 构建地图
     * @description 设置点标
     */
    const _initMarker = useCallback(
        mapEl => {
            const $marker = new AMap.Marker({
                position: new AMap.LngLat(lng, lat),
                icon: new AMap.Icon({                    
                    size: new AMap.Size(27, 41),
                    imageSize: new AMap.Size(27, 41),
                    image: '/static/crop/default-marker.png',
                }),
            });
            mapEl.add($marker);
        },
        [lng, lat],
    );

    /**
     * 初始化
     * @description 构建地图
     */
    useEffect(
        () => {
            const dayOrNight = calcDayOrNight();
            const $map = _map.current = new AMap.Map(
                ID_AMAP_MAP,
                {
                    zoom: 16,
                    center: [lng, lat],
                    features: ['bg', 'road', 'point'],
                    mapStyle: dayOrNight === 'day' ? MAP_THEME_DAY : MAP_THEME_NIGHT,
                },
            );
            _initMarker($map);
        },
        [
            lng,
            lat,
            _initMarker,
        ],
    );

    return <div id={ID_AMAP_MAP}></div>
}