import React, { useState, useCallback, useEffect } from "react";
import './Search.css';

/** 搜索框 */
export const Search = ({ data, onResultPress }) => {
    const [search, setSearch] = useState('');
    const [result, setResult] = useState([]);
    const [visible, setVisible] = useState(false);

    /**
     * 事件处理
     * @description 处理输入文本变化
     */
    const _handleSearchChange = useCallback(
        e => setSearch(e.target.value),
        [],
    );

    /**
     * 事件处理
     * @description 处理清空文本
     */
    const _handleSearchClear = useCallback(
        () => setSearch(''),
        [],
    );

    /**
     * 事件处理
     * @description 搜索结果单机
     */
    const _handleResultPress = useCallback(
        item => {
            setVisible(false);
            onResultPress(item);
        },
        [onResultPress],
    );

    /**
     * 副作用
     * @description 监听搜索值的变化
     */
    useEffect(
        () => {
            if (search) {
                setVisible(true);
                // 从元数据data中筛选
                const reg = new RegExp(search, 'i');
                const $result = data.filter(item => reg.test(item.title));
                setResult($result);
            } else {
                setVisible(false);
            }
        },
        [search, data],
    );

    /**
     * 渲染
     * @description 搜索结果列表
     */
    const _renderResult = useCallback(
        () => {
            if (Array.isArray(result) && result.length > 0) {
                return (
                    <div className='search_surface search_result'>
                        {
                            result.map((item, index) => (
                                <SearchItem
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    search={search}
                                    onClick={_handleResultPress}
                                />
                            ))
                        }
                    </div>
                )
            } else {
                return (
                    <div className='search_surface search_result'>
                        <div className='search_result-default'>暂无搜索结果</div>
                    </div>
                )
            }
        },
        [
            result,
            search,
            _handleResultPress,
        ],
    );

    return (
        <div className='search'>
            <div className='search_surface search_input-wrapper'>
                <input                    
                    value={search}
                    className='search_input'
                    placeholder='请输入搜索名称'
                    onChange={_handleSearchChange}                    
                />
                {
                    search ?
                        <img alt='' className='search_input-clear' src={icon_clear} onClick={_handleSearchClear} /> : null
                }
            </div>

            {
                visible ?
                    _renderResult() : null
            }
        </div>
    )
}

/** 搜索结果单项 */
const SearchItem = ({ item, index, search, onClick }) => {
    const className = 0 === index ?
        'search_result-item'
        :
        'search_result-item search_result-item-border';
    const $title = item.title.replace(new RegExp(`(${search})`, 'i'), '<em>$1</em>');
    const _handler = useCallback(
        () => onClick(item),
        [item, onClick],
    );

    return (
        <div key={item.id} className={className} onClick={_handler}>
            <span className='search_result-item-text' dangerouslySetInnerHTML={{ __html: $title }} />
        </div>
    )
}

const icon_clear = '/static/crop/icon/clear.png'