import React, { useEffect, useRef, useCallback } from "react";
import mitt from 'mitt'
import { ID_AMAP_MAP } from "../../config";
import { JS_CENTER_LNG, JS_CENTER_LAT, MAP_THEME_NIGHT, renderCustomMarkers } from "../../utils/utils";

const { AMap, AMapUI } = window
const emitter = mitt()

const INITIAL_ZOOM = 17
const EVENT_MAP_MANUAL_LOCATION = '__event_map_manual_location__'

/** 全局函数：监听RN->Web的消息，并分发给指定的处理函数 */
window.emitMapLocation = (name, message) => {
    switch (name) {
        case EVENT_MAP_MANUAL_LOCATION:
            emitter.emit(EVENT_MAP_MANUAL_LOCATION, JSON.parse(message));
            break;
        default:
            break;
    }
}

/**
 * APP用定位地图
 * @description 拖动地图或拖动Marker点获取定位
 */
export const MapWithLocation = () => {
    const _map = useRef(null);
    const _markers = useRef([]);
    const didMount = useRef(false);

    /**
     * 处理事件
     * @description 定位变化；通知APP更新地理位置
     */
    const _handleLocationChange = useCallback(
        ({ position: { lat, lng } }) => {
            if (!didMount.current) {
                didMount.current = true;
                return;
            }
            if (window.ReactNativeWebView) {
                const event = {
                    name: EVENT_MAP_MANUAL_LOCATION,
                    message: {
                        lat,
                        lng,
                    },
                };
                window.ReactNativeWebView.postMessage(JSON.stringify(event));
            }
        },
        [],
    );

    /**
     * 构建地图
     * @description 绘制拖拽点
     */
    const _initDraggableMarker = useCallback(
        mapIns => {
            AMapUI.loadUI(['misc/PositionPicker'], PositionPicker => {
                const positionPicker = new PositionPicker({
                    mode: 'dragMap',
                    map: mapIns,
                    iconStyle: {
                        url: '/static/crop/default-marker.png',
                        size: [27, 41],
                        ancher: [13.5, 20.5],
                    }
                });
                positionPicker.on('success', _handleLocationChange);
                positionPicker.start();
            });
        },
        [_handleLocationChange],
    );

    /**
     * 构建地图
     * @description 绘制手绘图
     */
    const _initCustomTile = useCallback(
        mapEl => {
            const tileLayer = new AMap.TileLayer.Flexible({
                zIndex: 2,
                cacheSize: 256,
                zooms: [15, 20],
                createTile: (x, y, z, success, fail) => {
                    const img = document.createElement('img');
                    img.onload = () => success(img);
                    img.onerror = () => fail();
                    img.src = `/static/tiles/night/${z}/${x}_${y}.png`;
                },
            });
            mapEl.add(tileLayer);
        },
        [],
    );

    /**
     * 构建地图
     * @description 绘制手绘标
     */
    const _initCustomMarker = useCallback(
        mapEl => {
            const $markers = _markers.current = renderCustomMarkers();
            $markers.forEach(it => it.hide());
            mapEl.add($markers);
        },
        [],
    );

    /**
     * 处理事件
     * @description 处理地图缩放
     */
    const _handleMapZoom = useCallback(
        mapEl => {
            const _zoom = mapEl.getZoom();
            const $markers = _markers.current;
            $markers.forEach(marker => {
                const { zoom2Show } = marker.getExtData();
                if (zoom2Show < _zoom) {
                    marker.hide();
                } else {
                    marker.show();
                }
            });
        },
        [],
    );

    /**
     * 监听器
     * @description 监听APP端的定位请求
     */
    const _onLocation = useCallback(
        e => {
            const { lng, lat } = e;
            const $map = _map.current;
            AMap.convertFrom([lng, lat], 'gps', (status, result) => {
                if (result.info === 'ok') {
                    const {
                        lng: $lng,
                        lat: $lat,
                    } = result.locations[0];

                    // 置于屏幕中间
                    $map.panTo(new AMap.LngLat($lng, $lat));
                }
            });
        },
        [],
    );

    /**
     * 初始化
     * @description 构建地图
     */
    useEffect(
        () => {
            const $map = _map.current = new AMap.Map(
                ID_AMAP_MAP,
                {
                    zoom: INITIAL_ZOOM,
                    center: [JS_CENTER_LNG, JS_CENTER_LAT],
                    features: ['bg', 'road', 'point'],
                    mapStyle: MAP_THEME_NIGHT,
                },
            );
            // 将地图限定在指定区域内
            const _bounds = new AMap.Bounds(
                new AMap.LngLat(101.991869, 23.100662),
                new AMap.LngLat(103.77852, 24.257693),
            );
            $map.setLimitBounds(_bounds);
            // 绘制地图元素
            _initCustomTile($map);
            _initDraggableMarker($map);
            _initCustomMarker($map);
            // 监听缩放
            $map.on('zoomend', () => _handleMapZoom($map));
        },
        [
            _initCustomTile,
            _initDraggableMarker,
            _initCustomMarker,
            _handleMapZoom,
        ],
    );

    /**
     * 初始化
     * @description 配置监听器
     */
    useEffect(
        () => {
            emitter.on(EVENT_MAP_MANUAL_LOCATION, _onLocation);
            return () => {
                emitter.off(EVENT_MAP_MANUAL_LOCATION, _onLocation);
            }
        },
        [_onLocation],
    );

    return <div id={ID_AMAP_MAP} />
}
