import React, { useCallback } from "react";
import "./InfoWindow.css";

/** 请求Marker点信息弹窗 */
const EVENT_MAP_INFO_WINDOW = '__event_map_info_window__'

/** 信息弹窗 */
export const InfoWindow = ({ item }) => {
    const _handler = useCallback(
        () => {
            if (window.ReactNativeWebView) {
                const event = {
                    name: EVENT_MAP_INFO_WINDOW,
                    message: {
                        id: item.targetId,
                        title: item.title,
                        type: item.cateName,
                    },
                };
                window.ReactNativeWebView.postMessage(JSON.stringify(event));
            }
        },
        [item],
    );

    return (
        <div className='infoWindow_container'>
            <div className='infoWindow_title'>{item.title}</div>
            {
                item.clickable ?
                    <div className='infoWindow_enter' onClick={_handler}>
                        <div className='infoWindow_enter-text'>进入</div>
                        <img alt='' className='infoWindow_enter-icon' src={icon_enter} />
                    </div> : null
            }
        </div>
    )
}

const icon_enter = '/static/crop/icon/enter.png'