import axios from "axios";

// const BASE_URL = 'http://192.168.2.150:3000'
const BASE_URL = 'http://api.jstour.hz2.3lengjing.com'

const codeMessage = {
    200: '服务器成功返回请求的数据',
    201: '新建或修改数据成功',
    202: '已提交请求',
    204: '删除数据成功',
    400: '请求内容有误',
    401: '请先登录',
    403: '禁止访问',
    404: '无法处理的请求',
    406: '请求的格式不可得',
    408: '请求已超时',
    410: '当前资源已被永久删除',
    422: '发生验证错误',
    500: '服务器出错',
    502: '网关错误',
    503: '服务不可用，服务器暂时过载或维护',
    504: '网关超时',
};

/**
 * 一般的http请求方式
 * @param method 请求方式
 */
const request = method => async (config, axiosConfig) => {
    const {
        url,
        body,
        headers = {},
    } = config

    // 请求参数
    let params = undefined;
    if (config && config.body) {
        params = 'GET' === method ? { params: body } : { data: body };
    }

    // 发起请求
    const response = axios.request({
        url,
        method,
        headers,
        baseURL: BASE_URL,
        ...params,
        ...axiosConfig,
    })
        .then(response => {  // 处理成功
            const { code, error } = response.data;
            if (200 === code) {
                return response.data;
            } else {
                const message = error || '未知业务错误';
                // showToast(message);  // TODO: 如何显示错误
                return {
                    code,
                    error: message,
                }
            }
        })
        .catch(error => {
            console.log('http-error', error, error.response)
            if (error.response) {
                const status = error.response.status;
                const message = codeMessage[status] || '未知网络错误'
                // showToast(message);  // TODO: 如何显示错误
                return {
                    code: status,
                    error: message,
                };
            } else {
                // showToast('请检查您的网络连接'); // TODO: 如何显示错误
                return {
                    code: 500,
                    error: '未知网络错误',
                };
            }
        })
    return response;
}


/**
 * 一般的GET请求
 * @param config 适配业务逻辑的配置
 * @param axiosConfig 可选，覆盖默认的axios配置
 */
export const httpGet = request('GET');

/**
 * 一般的POST请求
 * @param config 适配业务逻辑的配置
 * @param axiosConfig 可选，覆盖默认的axios配置
 */
export const httpPost = request('POST');