import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { MapWithTile } from './pages/MapWithTile';
import { MapWithRoute } from './pages/MapWithRoute';
import { MapWithMarker } from './pages/MapWithMarker';
import { MapWithLocation } from './pages/MapWithLocation';

/**
 * App
 * @description 路由配置
 */
export function App() {
    return (
        <BrowserRouter>
            <Route path='/map-with-tile' component={MapWithTile} />
            <Route path='/map-with-route' component={MapWithRoute} />
            <Route path='/map-with-marker' component={MapWithMarker} />
            <Route path='/map-with-location' component={MapWithLocation} />
        </BrowserRouter>
    )
}