import { httpGet } from "./request";
import { scale2Zoom } from "../utils/utils";

/** 点击可弹出弹窗的编辑点名称 */
const CLICKABLE_MARKER_NAME = [
    'entertainment',
    'shop',
    'scene',
    'accommodation',
    'food',
]

/** 可交互的标记点名称 */
const INTERACTIVE_MARKER_NAME = [
    'traffic',
    'toilet',
    'park',
    ...CLICKABLE_MARKER_NAME,
];

/** 获取地图标记点 */
export const getMapMarker = async () => {
    const response = await httpGet({
        url: '/jstour/user/getJsMapList',
    });
    return response;
}

/** 获取地图点标记（过滤后的） */
export const getMapMarkerByFilter = async () => {
    const response = await getMapMarker();
    const result = {
        variableMarkers: [],
        staticMarkers: [],
    };

    if (response && response.result) {
        response.result.forEach(item => {
            const {
                lat,
                lng,
                scale,
                targetId,
                merchantNatureName,
                cate: {
                    title,
                    name: mapCateName,
                },
            } = item;

            if (lat && lng) {
                const cateName = merchantNatureName || mapCateName;
                const customItem = {
                    targetId,
                    cateName,
                    lng,
                    lat,
                    id: item.id,
                    title: item.title || title,
                    symbol: item.symbol,
                    zoom: scale2Zoom(scale),
                    clickable: CLICKABLE_MARKER_NAME.indexOf(cateName) >= 0,
                };

                if (0 <= INTERACTIVE_MARKER_NAME.indexOf(cateName)) {
                    result['variableMarkers'].push(customItem);
                } else {
                    result['staticMarkers'].push(customItem);
                }
            }
        });
        return result;
    }
    return result;
}

/** 获取某一线路的所有顺序的点标 */
export const getRouteMarkers = async id => {
    const response = await httpGet({
        body: {
            id,
        },
        url: '/jstour/user/getJsRouteThemeDetail',
    });

    if (response && response.result) {
        const { daily = [] } = response.result;
        const markers = daily.reduce(
            (arr, { scenic = [] }) => {
                // 舍弃没有经纬度的点
                const _markers = scenic.filter(({ lng, lat }) => lng && lat);
                return [...arr, ..._markers];
            },
            [],
        );
        return markers;
    }
    return [];
}