const { AMap } = window

/** 白间地图 */
export const MAP_THEME_DAY = 'amap://styles/183ceebaffb4add9fb5ddccc206f2a0d'
/** 夜间地图 */
export const MAP_THEME_NIGHT = 'amap://styles/009533ed075e824e2b53a4ca415cae2f'
/** 建水中心-经度 */
export const JS_CENTER_LNG = 102.8308296204
/** 建水中心-维度 */
export const JS_CENTER_LAT = 23.6165502536
/** 建水-手绘元素标记点-元数据 */
export const JS_CUSTOM_MARKERS = [
    {
        title: '建水古城',
        icon: '/static/scene/chao-yang-lou.png',
        zoom2Show: 14,
        lng: 102.8308188915,
        lat: 23.6163831390,
        zIndex: 666,
    },
    {
        title: '团山',
        icon: '/static/scene/village.png',
        zoom2Show: 15,
        lng: 102.73954213,
        lat: 23.64313544,
        zIndex: 555,
    },
    {
        title: '苍台',
        icon: '/static/scene/village.png',
        zoom2Show: 15,
        lng: 102.6166667,
        lat: 23.27916667,
        zIndex: 444,
    },
]

/** 绘制手绘元素的高德点标 */
export const renderCustomMarkers = () => {
    return JS_CUSTOM_MARKERS.map(marker => {
        const $marker = new AMap.Marker({
            position: new AMap.LngLat(
                marker.lng,
                marker.lat,
            ),
            icon: new AMap.Icon({
                size: new AMap.Size(54, 43.5),
                imageSize: new AMap.Size(54, 43.5),
                image: marker.icon,
            }),
            extData: marker,
            zIndex: marker.zIndex,
        });
        return $marker;
    });
}

/** 计算当前为白天还是黑夜 */
export const calcDayOrNight = () => {
    const hour = new Date().getHours();
    if (hour >= 6 && hour < 18) {
        return 'day'
    } else {
        return 'night'
    }
}

/** 高德地图比例尺到缩放等级的转换 */
export const scale2Zoom = scale => {
    if (scale <= 10) return 19;
    else if (scale <= 25) return 18;
    else if (scale <= 50) return 17;
    else if (scale <= 100) return 16;
    else if (scale <= 200) return 15;
    else if (scale <= 500) return 14;
    else if (scale <= 1000) return 13;
    else if (scale <= 2000) return 12;
    else if (scale <= 5000) return 11;
    else if (scale <= 10000) return 10;
    else if (scale <= 20000) return 9;
    else if (scale <= 30000) return 8;
    else if (scale <= 50000) return 7;
    else if (scale <= 100000) return 6;
    else if (scale <= 200000) return 5;
    else if (scale <= 500000) return 4;
    else if (scale <= 1000000) return 3;
    else if (scale > 1000000) return 2;
    return 20;
}